// Requiert SmoothScroller (smooth-scroller.js)
// Requiert swipeable

import SmoothScroller from 'smooth-scroller.js';
import swipeable from 'swipeable';

function Tabs( args ) {
	let that = this;

	let startEvent = new Event( 'changetabstart' );
	let endEvent = new Event( 'changetabend' );
	let scrollTimeout;
	let resizeTimeout;

	args = args || {};

	if( ! args.container || ! ( args.container instanceof HTMLElement ) ) {
		throw new Error("tabs : args.container is required and must be a single HTMLElement");
	} else {
		this.container = args.container;
	}

	args.navSel = args.navSel || '.tabs-block-nav';
	args.navElSel = args.navElSel || '.tabs-nav-item';
	args.navLinkSel = args.navLinkSel || '.tabs-nav-link';
	args.tabElSel = args.tabElSel || '.tab';
	args.scrollElSel = args.scrollElSel || '.tabs-block-tabs';
	args.activeClass = args.activeClass || 'active';
	args.activeCandClass = args.activeCandClass || 'active-candidate';
	args.debug = args.debug || false;

	this.args = args;

	this.init = function() {
		let navEls = that.container.querySelectorAll( that.args.navElSel );
		let activeTab = null;

		that.nav = that.container.querySelector( that.args.navSel );
		that.scrollEl = that.container.querySelector( that.args.scrollElSel );
		that.activeTab = null;
		that.tabs = [];
		that.listeningToScroll = true;

		that.smoothScroller = new SmoothScroller({
			parent: that.scrollEl,
		});

		navEls.forEach( function( thisEl, index ) {
			let tab = {};
			let tabHash;

			tab.navEl = thisEl;
			tab.navLink = thisEl.querySelector( that.args.navLinkSel );
			tab.active = false;
			tab.index = index;
			tab.activeInitial = false;
			tabHash = tab.navLink.hash;

			if( tabHash ) {
				try {
					tab.tabEl = document.querySelector( tabHash );
				} catch( error ) {
					tab.tabEl = null;
					console.error( "tabs : the following tab link's hash (" + tabHash + ") is invalid. Tabs initialization will fail." );
					console.log( tab.navLink );
				}

				if( that.args.debug && ! tab.tabEl ) {
					console.log( "tabs : the following tab link's hash (" + tabHash + ")'s corresponding element was not found. It will not work." );
					console.log( tab.navLink );
				}
			} else {
				tab.tabEl = null;
				if( that.args.debug ) {
					console.log("tabs : the following tab link has no hash, and thus will not work.");
					console.log( tab.navLink );
				}
			}

			that.tabs.push( tab );

			// C'est le premier avec la classe active ? C'est lui l'actif.

			if( ! activeTab && that.tabs[index].navEl.classList.contains( args.activeCandClass ) ) {
				activeTab = that.tabs[index];
			}
		} );

		// Il n'y a aucun actif ? Ça sera le premier du bord.

		if( ! activeTab ) {
			activeTab = that.tabs[0];
		}

		activeTab.activeInitial = true;
		that.setActiveTab( activeTab );

		// On ouvre l'onglet actif

		that.openActiveTab( false );
	};


	this.setActiveTab = function( tabToSet ) {
		that.tabs.forEach( function( thisTab ) {
			thisTab.active = false;
			thisTab.navEl.classList.remove( args.activeClass );
			thisTab.tabEl.classList.remove( args.activeClass );
		} );

		tabToSet.active = true;
		tabToSet.navEl.classList.add( args.activeClass );
		tabToSet.tabEl.classList.add( args.activeClass );
		that.activeTab = tabToSet;
	};


	this.openActiveTab = function( animate ) {
		if( 'undefined' == typeof animate ) {
			animate = true;
		}

		this.openTab( that.activeTab, animate );
	};


	this.openTab = function( tabToOpen, animate = true ) {
		if( tabToOpen.tabEl ) {
			if( that.args.debug ) {
				console.log( "tabs : Opening tab :" );
				console.log( tabToOpen );
			}


			tabToOpen.tabEl.dispatchEvent( startEvent );

			let scrollOptions = {
				target: tabToOpen.tabEl,
				setHash: false,
			};

			if( animate ) {
				that.listeningToScroll = false;
			} else {
				scrollOptions.scrollToOptions = {
					behavior: 'auto',
				};
			}

			// Réactiver l'écoute de scroll/resize après le scroll

			if( animate ) {
				that.scrollEl.addEventListener( 'smoothscrollend', function tabScrollEnd() {
					if( that.args.debug ) console.log( "tabs : Container scroll over. Setting listeningToScroll to true." );
					tabToOpen.tabEl.dispatchEvent( endEvent );
					that.listeningToScroll = true;
					that.scrollEl.removeEventListener( 'smoothscrollend', tabScrollEnd );
				} );
			}

			that.smoothScroller.scrollTo( scrollOptions );

			if( ! animate ) {
				tabToOpen.tabEl.dispatchEvent( endEvent );
			}
		}
	};

	this.previousTab = function() {
		if( 0 < that.activeTab.index ) {
			that.setActiveTab( that.tabs[ that.activeTab.index - 1 ] );
			that.openActiveTab();
		}
	};

	this.nextTab = function() {
		if( that.tabs.length - 1 > that.activeTab.index ) {
			that.setActiveTab( that.tabs[ that.activeTab.index + 1 ] );
			that.openActiveTab();
		}
	};

	this.getCenterTab = function() {
		let scrollElRect = that.scrollEl.getBoundingClientRect();
		let scrollElCenter = {
			x: scrollElRect.left + ( scrollElRect.width / 2 ),
			// y: containerRect.top + ( containerRect.height / 2 ),
			y: scrollElRect.top, // Se placer en haut du contenu de l'onglet réduit les chances qu'un élément fixed bloque le chemin
		};

		let centerEl;
		let centerTabEl;

		// On descend le point cible de 10px jusqu'à ce qu'on trouve un point qui est effectivement dans l'onglet
		// (Protection contre les éléments fixed qui pourraient être dans les jambes
		do {
			scrollElCenter.y += 10;
			centerEl = document.elementFromPoint( scrollElCenter.x, scrollElCenter.y );
			centerTabEl = centerEl ? centerEl.closest( that.args.tabElSel ) : null;
		} while( ! centerTabEl && scrollElCenter.y < scrollElRect.bottom );

		let centerTab = null;

		// On a trouvé l'onglet qui est actif. Allons chercher son élément d'array correspondant.
		if( centerTabEl ) {
			for( let thisTab of that.tabs ) {
				if( thisTab.tabEl.isSameNode( centerTabEl ) ) {
					centerTab = thisTab;
					break;
				}
			}
		}
		

		if( that.args.debug ) {
			console.log( "tabs : Element at container center :" );
			console.log( centerEl );
			console.log( "tabs : Found center tab element :" );
			console.log( console.log( centerTabEl ) );
			console.log( "tabs : Found center tab :" );
			console.log( console.log( centerTab ) );

			// Ajoute un point rouge pour voir le point central sur lequel on se base
			console.log('scrollElCenter:');
			console.log(scrollElCenter);
			let oldTestEl = document.querySelector('.tabs-center-test-dot');

			if( oldTestEl ) {
				oldTestEl.remove();
			}

			let testEl = document.createElement('div');
			testEl.classList.add('tabs-center-test-dot');
			testEl.style.cssText = "pointer-events: none; position: fixed; z-index: 9000; top: " + scrollElCenter.y + "px; left: " + scrollElCenter.x + "px; transform: translate( -50%, -50%); background-color: red; width: 10px; height: 10px; border-radius: 100%;";

			document.documentElement.appendChild(testEl);
		}

		return centerTab;
	};


	this.init();



	/*** Évènements *********************************************************************************/

	// Clic dans le menu

	that.tabs.forEach( function( thisTab ) {
		thisTab.navLink.addEventListener( 'click', function( event ) {
			event.preventDefault();

			that.setActiveTab( thisTab );
			that.openActiveTab();
		} );
	} );


	// Swipe sur la barre de menu

	swipeable(
		that.nav,
		function( swipe ) {
			if( swipe.left ) {
				that.previousTab();
			} else if( swipe.right ) {
				that.nextTab();
			}
		},
		{
			timeThreshold: 600,
			distanceThreshold: 100,
		}
	);


	// Scroll manuel

	that.scrollEl.addEventListener( 'scroll', function( event ) {
		if( that.listeningToScroll ) {
			if( scrollTimeout ) {
				clearTimeout( scrollTimeout );
			}

			scrollTimeout = setTimeout( function() {
				if( that.args.debug ) {
					console.log( "tabs : Manual scroll over. ScrollEvent :" );
					console.log( event );
				}

				// Scroll fini ! On regarde quel onglet en ce moment ?
				let centerTab = that.getCenterTab();

				// On a trouvé l'élément d'array de l'onglet. Rendons-le actif.
				if( centerTab ) {
					that.setActiveTab( centerTab );
				}
			}, 100 );
		}
	} );


	// Réajustement post-resize

	window.addEventListener( 'resize', function() {
		if( resizeTimeout ) {
			clearTimeout( resizeTimeout );
		}

		resizeTimeout = setTimeout( function() {
			if( that.args.debug ) console.log( "tabs : Resize over. Re-opening active tab." );
			that.openActiveTab();
		}, 50 );
	} );
}

export default Tabs;